import React from 'react'
import {
  Form,
  DropdownItemProps,
  DropdownProps,
  Flag,
  FlagNameValues
} from 'semantic-ui-react'
import {  FirstLang } from '../../api/storage'
import { availableUiLangs, MessageGetter, UILanguage, localStorageUiLangKey } from '../../api/i18n'


interface FirstLangSwitcherProps {
  i18n: MessageGetter
  label?: string
  style?: React.CSSProperties
  value?: FirstLang
  onChange: any
}

export const FirstLangSwitcher = (props: FirstLangSwitcherProps) => {
  const { i18n, value } = props
  const options = [
    {
      key: 'null',
      text: i18n('settings_language_firstlang_0'),
      value: FirstLang.NULL
    },
    {
      key: 'sv',
      text: i18n('lang_sv'),
      value: FirstLang.SV,
      flag: { name: 'se' }
    },
    {
      key: 'da',
      text: i18n('lang_da'),
      value: FirstLang.DA,
      flag: { name: 'dk' }
    },
    {
      key: 'no',
      text: i18n('lang_no'),
      value: FirstLang.NO,
      flag: { name: 'no' }
    },
    {
      key: 'fi',
      text: i18n('lang_fi'),
      value: FirstLang.FI,
      flag: { name: 'fi' }
    },
    {
      key: 'de',
      text: i18n('lang_de'),
      value: FirstLang.DE,
      flag: { name: 'de' }
    }
  ]
  const selectedOption = options.find(option => option.value === value)
  const flag = (!!selectedOption ? selectedOption.flag || {} : {}) as any
  const flagName = flag.name as FlagNameValues | undefined

  return <Form.Field style={ props.style }>
  <label>
    <p>
      { flagName && <Flag name={ flagName} /> }
      { i18n('settings_language_firstlang_header') }
    </p>
  </label>
  <Form.Dropdown
    id='firstlang'
    fluid
    selection
    value={ value }
    options={ options }
    onChange={ props.onChange }
  />
</Form.Field>
}

const getFlagName = (uiLang: UILanguage): FlagNameValues|undefined => {
  switch (uiLang) {
    case 'sv': return 'se'
    case 'da': return 'dk'
    case 'de': return 'de'
    case 'en': return 'uk'
    case 'nb': return 'no'
    default: return undefined
  }
}

// Store UI Language in LocalStorage for now...
interface UILangSwitcherProps {
  i18n: MessageGetter
  currentLang: UILanguage
  style?: React.CSSProperties
}
interface UILangSwitcherState {
  value: UILanguage
  loading: boolean
}
export class UILangSwitcher extends React.Component<UILangSwitcherProps, UILangSwitcherState> {
  options: DropdownItemProps[]

  constructor(props: UILangSwitcherProps) {
    super(props)
    const { i18n } = props

    this.options = availableUiLangs.map((lang: UILanguage) => ({
      key: lang,
      text: i18n(`lang_${lang.replace('nb', 'no')}`),
      value: lang,
      flag: { name: getFlagName(lang) }
    }))

    this.state = {
      value: this.props.currentLang,
      loading: false
    }
  }

  handleChange = (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const value = data.value as UILanguage

    localStorage.setItem(localStorageUiLangKey, value)
    this.setState({ value, loading: true })

    setTimeout(() => window.location.reload(), 1000)
  }

  render() {
    // options: [{ label: string, value: string }, {}...]
    const { i18n, currentLang } = this.props
    
    const flagName = getFlagName(currentLang)

    return (<Form.Field style={ this.props.style }>
      <label>
        <p style={{ margin: '1em 0 .5em' }}>
          { flagName && <Flag name={ flagName } /> }
          { i18n('settings_language_ui_header') }
        </p>
      </label>
      <Form.Dropdown
        fluid
        loading={ this.state.loading }
        disabled={ this.state.loading }
        selection
        value={ this.state.value }
        options={ this.options }
        onChange={ this.handleChange }
      />
    </Form.Field>)
  }
}