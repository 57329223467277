import React, { Component } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { FatalError, FatalErrorMessage, ErrorType } from '../Errors'
import Main from '../Main'
import { HostType, OfficeInfo, User, OribiApp, PlatformType } from '../../types'
import { requestGraphUser, checkUserLicense } from '../Auth/helpers'
import PropertiesService, { LicenseType } from '../../api/storage'
import LicenseKeyModal from './LicenseKeyModal'
import Translator from '../../api/i18n'
import { getOribiSpeakId } from '../TTS/api'
import { BrowserInfo, getBrowserInfo, Browser } from '../../api/browserInfo'
import { getAppSlug, getSourceLang } from '../../app'
import LoginModal from './LoginModal'
import SchoolIdModal from './SchoolIdModal'

/**
 * Before the app can do its thing, some things must be determined:
 * - Init Office JS API
 * - Determine Office host application and platform
 * - Authenticate user email and ID
 */
// const delay = (ms: number = 1500) => new Promise(resolve => setTimeout(resolve, ms))

interface Props {
  app: OribiApp
  enableAppSwitcher?: boolean
}

interface State {
  officeInfo: OfficeInfo
  user: User
  lastError: FatalError|undefined
  statusText: string
  displayLoginBtn: boolean
  licenseMessage: string
  onLine: boolean
  oribiSpeakExtensionId: string
  license: LicenseType
  trial_start?: string // Date().toJSON()
  trial_expired?: boolean
  school_id?: number
  license_key?: string
  log: string[]
  speechSynthesisVoice?: SpeechSynthesisVoice
}

const { Office } = window

export default class Startup extends Component<Props, State> {
  // Key used to get cached accessToken from localStorage
  tokenKey: string
  propertiesService: PropertiesService
  validSchoolIds: number[]
  i18n: Translator
  browserInfo: BrowserInfo

  constructor(props: Props) {
    super(props)
    const { app } = props

    this.propertiesService = new PropertiesService(app)
    this.tokenKey = 'oribiMsalAccessToken'
    this.validSchoolIds = []
    this.i18n = new Translator(undefined, app)
    this.browserInfo = getBrowserInfo()
    
    document.documentElement.lang = this.i18n.lang
    document.documentElement.dataset.app = getAppSlug(app)

    let lastError
    if ( !navigator.onLine ) {
      lastError = new FatalError(ErrorType.OFFLINE)
    } else if ( this.browserInfo.browser === Browser.TEAMS ) {
      lastError = new FatalError(ErrorType.TEAMS_BROWSER)
    }

    this.state = {
      officeInfo: {
        host: HostType.Unknown,
        platform: PlatformType.Unknown
      },
      user: {
        email: '',
        id: ''
      },
      lastError: lastError,
      statusText: this.i18n._('status_starting', [props.app]),
      displayLoginBtn: false,
      licenseMessage: '',
      onLine: navigator.onLine,
      oribiSpeakExtensionId: '',
      license: LicenseType.UNKNOWN,
      trial_start: undefined,
      trial_expired: false,
      school_id: undefined,
      license_key: undefined,
      log: []
    }

    this.onLoggedIn = this.onLoggedIn.bind(this)
  }

  async componentDidMount() {
    // Add event listener offline to detect network loss.
    window.addEventListener('offline', () => this.setState({ onLine: false }))
    window.addEventListener('online', () => this.setState({ onLine: true }))

    // await new Promise(resolve => setTimeout(resolve, 2000))

    const officeInfo: OfficeInfo = this.state.officeInfo

    if ( typeof Office !== 'undefined' ) {
      const { host, platform } = await Office.onReady()
      // Determine Office host application
      if ( host === Office.HostType.Word ) {
        officeInfo.host = HostType.Word
      } else if ( host === Office.HostType.OneNote ) {
        officeInfo.host = HostType.OneNote
      } else {
        officeInfo.host = HostType.Other
      }

      // And platform, just in case (not that important...)
      officeInfo.platform = platform as any

      this.setState({ officeInfo })
    }
    const isOfficeInit = Object.values(officeInfo).every(value => !!value)

    if ( !isOfficeInit ) {
      this.setState({
        lastError: new FatalError(ErrorType.NO_OFFICE)
      })
      return;
    }

    const { platform, host } = officeInfo
    const { app } = this.props

    // Body.getRange requires WordApi 1.3...
    // https://github.com/OfficeDev/office-js-docs/blob/master/reference/requirement-sets/word-api-requirement-sets.md
    const isWord = host === HostType.Word
    const isWordUpdated = Office.context.requirements.isSetSupported('WordApi', 1.3)
    if ( isWord && !isWordUpdated ) {
      let minVersion

      switch ( platform ) {
        case PlatformType.PC:
          minVersion = '1612 (Build 7668.1000)'
          break
        case PlatformType.Mac:
          minVersion = '15.32'
          break
        case PlatformType.iOS:
          minVersion = '2.22'
          break
        default:
          minVersion = '2016'
          break
      }
      
      const message = this.i18n._('error_word_api', [app, minVersion])

      this.setState({
        lastError: new FatalError(ErrorType.WORD_API, message)
      })
      return;
    }

    const isOneNote = host === HostType.OneNote
    const isOneNoteUpdated = Office.context.requirements.isSetSupported('OneNoteApi', 1.1)
    if ( isOneNote && !isOneNoteUpdated ) {
      const message = this.i18n._('error_onenote_api', [app])
      this.setState({
        lastError: new FatalError(ErrorType.ONENOTE_API, message)
      })
      return;
    }

    const isMacOS = officeInfo.platform === PlatformType.Mac
    const macOSInfo = navigator.userAgent.match(/OS\sX\s(\d\d_\d\d)/)
    if ( isMacOS && !!macOSInfo ) {
      // macOSInfo is either something like ["OS X 10_15", "10_15"] or null
      const macOSVersion = macOSInfo[1].replace('_', '.') // "10.15"
      const versionNumber = parseFloat(macOSVersion) // 10.15

      // Require macOS High Sierra or later
      if ( versionNumber < 10.13 ) {
        const message = this.i18n._('error_macos', [app])
        this.setState({
          lastError: new FatalError(ErrorType.MACOS, message)
        })
        return;
      }
    }

    const safariInfo = navigator.appVersion.match(/Version\/(\d+\.?\S*)\sSafari/)
    if ( !!safariInfo ) {
      const safariVersion = safariInfo[1]
      const versionNumber = parseFloat(safariVersion)
      if ( versionNumber < 11.1 ) {
        const message = this.i18n._('error_safari', [app])
        this.setState({
          lastError: new FatalError(ErrorType.SAFARI, message)
        })
        return;
      }
    }

    this.setState({
      statusText: this.i18n._('signing_in')
    })

    // If simulated user is set, skip actual sign in in flow and use stored user
    const simulatedUser = localStorage.getItem('OribiAppSimulatedUser')
    if ( !!simulatedUser ) {
      const user = JSON.parse(simulatedUser)
      return this.onLoggedIn(user)
    }

    // Look for accessToken in localStorage
    let cachedAccessToken = localStorage.getItem(this.tokenKey) || ''

    // Try to get graph user with cached accessToken
    const graphResponse = await requestGraphUser(cachedAccessToken)
    if ( !graphResponse.success || !graphResponse.user ) {
      this.setState({ displayLoginBtn: true })
      return
    }

    const { user } = graphResponse
    this.onLoggedIn(user)
    return // Done signing in
  }

  async onLoggedIn(user: User) {
    this.setState({ user })

    const { id } = user
    this.setState({ statusText: this.i18n._('status_getting_settings') })

    // Listen for storage change events
    this.propertiesService.addOnChangedListener(({ property, newValue }) => {
      // Make sure new values are correctly typed
      switch ( property ) {
        case 'license':
          this.setState({
            license: newValue as LicenseType
          })
          break
        case 'trial_start':
          this.setState({
            trial_start: !!newValue ? newValue.toString() : undefined
          })
          break
        case 'trial_expired':
          this.setState({
            trial_expired: !!newValue
          })
          break
        case 'school_id':
          this.setState({
            school_id: Number(newValue)
          })
          break
        case 'license_key':
          this.setState({
            license_key: !!newValue ? newValue.toString() : undefined
          })
          break
        default: break
      }
    })

    // Check User License...
    const storage = await this.propertiesService.init(id)
    let {
      license,
      trial_start,
      school_id,
      license_key
    } = storage

    if ( license === undefined ) license = LicenseType.UNKNOWN
    const { app } = this.props

    // TODO: Prevent flash of license key box?
    // license = LicenseType.UNKNOWN
    this.setState({ license, trial_start, school_id, license_key  })

    const licenseInfo = await checkUserLicense({
      user,
      app,
      trialStart: trial_start,
      schoolId: school_id,
      licenseType: license,
      licenseKey: license_key,
      handleStatusChange: (statusText) => this.setState({ statusText }),
      handleTrialExpired: () => {
        this.propertiesService.set({ trial_expired: true })
      },
      i18n: this.i18n._
    })

    const {
      type: licenseType,
      message: licenseMessage,
      validSchoolIds
    } = licenseInfo

    if ( !!licenseMessage ) this.setState({ licenseMessage })
    if ( validSchoolIds ) this.validSchoolIds = validSchoolIds

    this.propertiesService.set({ license: licenseType })

    this.setState({ statusText: '' })

    const oribiSpeakExtensionId = await getOribiSpeakId()
    this.setState({ oribiSpeakExtensionId })

    // If Oribi Speak isn't installed, see if local TTS is available
    if ( !oribiSpeakExtensionId && 'speechSynthesis' in window ) {
      const { speechSynthesis } = window
      const voices: SpeechSynthesisVoice[] = await new Promise(resolve => {
        let timesChecked = 0
        const checker = setInterval(() => {
          const v = speechSynthesis.getVoices()
          if ( v.length > 0 || timesChecked >= 10 ) {
            clearInterval(checker)
            resolve(v)
          }
          timesChecked++
        }, 10)
      })

      // Look for a voice in the same language as suggestions
      const sourceLang = getSourceLang(app)
      const voice = voices.find(voice => voice.lang.split('-')[0] === sourceLang)
      if ( voice ) this.setState({
        speechSynthesisVoice: voice
      })
    }
  }

  log = (...args: any[]) => {
    this.setState(prevState => {
      const string = Date.now() + ': ' + args.join(' ')
      prevState.log.splice(0, 0, string)
      return prevState
    })
    console.log(...args)
  }

  render() {
    const { app, enableAppSwitcher } = this.props
    const {
      officeInfo,
      user,
      lastError,
      statusText,
      displayLoginBtn,
      licenseMessage,
      license: licenseType = LicenseType.UNKNOWN, 
      trial_expired: trialExpired,
      trial_start: trialStart,
      license_key: licenseKey
    } = this.state

    if ( lastError !== undefined ) return <FatalErrorMessage app={ app } error={ lastError } />

    const licensed = ![
      LicenseType.UNKNOWN,
      LicenseType.GREYLIST,
      LicenseType.UNAUTHORIZED
    ].includes(licenseType)

    // Check if all properties in an object has a value (!!true, 0 or false)
    const isSaturated = (...objects: object[]) => {
      const hasValue = (value: any) => !!value || value === 0 || value === false
      const everyKeyHasValue = (obj: object) =>
        Object.keys(obj).length && Object.values(obj).every(hasValue)

      return objects.every(everyKeyHasValue)
    }
    
    const isReady = isSaturated(user, officeInfo) && licensed
    
    const i18n = this.i18n._

    if ( isReady ) return <Main
      app={ app }
      officeInfo={ officeInfo }
      user={ user }
      propertiesService={ this.propertiesService }
      enableAppSwitcher={ !!enableAppSwitcher }
      licenseMessage={ licenseMessage }
      i18n={ this.i18n }
      currentLang={ this.i18n.lang }
      oribiSpeakExtensionId={ this.state.oribiSpeakExtensionId }
      speechSynthesisVoice={ this.state.speechSynthesisVoice }
    />

    // If not ready yet, display dimmer or modal
    if ( displayLoginBtn ) return <LoginModal
      app={ app }
      enableSimulator={ !!enableAppSwitcher }
      tokenKey={ this.tokenKey }
      i18n={ i18n }
      onSuccess={(user) => {
        this.setState({ displayLoginBtn: false })
        this.onLoggedIn(user)
      }}
      onError={(error) => {
        this.setState({ lastError: error })
      }}
    />

    if ( licenseType === LicenseType.GREYLIST ) return <SchoolIdModal
      enableAppSwitcher={ !!enableAppSwitcher }
      app={ app }
      user={ user }
      validIds={ this.validSchoolIds }
      onSuccess={(id: number) => {
        this.propertiesService.set({ license: LicenseType.SCHOOL })
        this.propertiesService.set({ school_id: id })
      }}
      onFail={() => {
        this.propertiesService.set({ license: LicenseType.UNAUTHORIZED })
      }}
      i18n={ i18n }
    />

    if ( licenseType === LicenseType.UNAUTHORIZED ) return <LicenseKeyModal
      app={ app }
      user={ user }
      trialExpired={ !!trialExpired }
      trialStart={ trialStart }
      licenseKey={ licenseKey }
      translator={ this.i18n }
      propertiesService={ this.propertiesService }
      onSuccess={(key: string, message: string) => {
        this.setState({ licenseMessage: message })
        this.propertiesService.set({
          license_key: key,
          license: LicenseType.LICENSE_KEY
        })
      }}
      onTrialActivated={(message) => {
        this.setState({ licenseMessage: message })
      }}
    />

    // If loading, display dimmer
    return <Dimmer page active={ !isReady }>
      <Loader active={ true } content={ statusText } />
    </Dimmer>
  }
}