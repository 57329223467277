import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import PropertiesService from '../../api/storage'

interface Props {
  propertiesService: PropertiesService
}

const ClearSettingsBtn = (props: Props) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const { propertiesService } = props
  return <Button
    loading={ loading }
    negative={ error }
    onClick={() => {
      setLoading(true)
      propertiesService.clear().then(success => {
        setLoading(false)
        if ( success ) {
          window.location.reload()
        } else {
          setError(true)
        }
      })
    }}
    fluid
    content={error ? 'Prova igen': 'Återställ inställningar' }
  />
}

export default ClearSettingsBtn