import React from 'react'
import { Button, Accordion } from 'semantic-ui-react'
import { OribiApp } from '../../types'
import PropertiesService, { LicenseType } from '../../api/storage'
import { MessageGetter } from '../../api/i18n'

interface Props {
  app: OribiApp
  propertiesService: PropertiesService
  trialActive: boolean
  i18n: MessageGetter
}

interface State {
  loading: boolean
}

export default class ActivateBtn extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  render = () => {
    const { app, trialActive, propertiesService, i18n } = this.props
    const btnText = i18n('register_app', [app])

    return <Accordion defaultActiveIndex={undefined} panels={[{
      key: 'activate',
      title: btnText,
      content: {
        content: (<>
          <p>{ i18n('settings_license_register_description', [app]) }</p>
          { trialActive && <p>{ i18n('settings_license_register_continue_trial_notice') }</p>}
          <Button
            primary
            fluid
            loading={ this.state.loading }
            content={ btnText }
            icon='unlock'
            onClick={async () => {
              this.setState({ loading: true })
              await propertiesService.setAsync({ license: LicenseType.UNKNOWN })
              window.location.reload()
            }}
          />
        </>)
      }
    }]} />
  }
}