export enum Browser {
  CHROME = 'Google Chrome',
  EDGE = 'Microsoft Edge',
  TEAMS = 'Microsoft Teams',
  FIREFOX = 'Mozilla Firefox',
  SAFARI = 'Safari',
  UNKNOWN = 'N/A'
}

export interface BrowserInfo {
  browser: Browser,
  version: string
}

// Browser names as they appear in the navigator.userAgent string
type BrowserName = 'Teams' | 'Edg' | 'Chrome' | 'Safari' | 'Firefox' | 'AppleWebKit'

const getBrowser = (userAgentName: BrowserName): Browser => {
  switch(userAgentName) {
    case 'Teams':
      return Browser.TEAMS
    case 'Edg':
      return Browser.EDGE
    case 'Chrome':
      return Browser.CHROME
    case 'Safari':
    case 'AppleWebKit':
      return Browser.SAFARI
    case 'Firefox':
      return Browser.FIREFOX
    default:
      return Browser.UNKNOWN
  }
}

/**
 * The navigator.userAgent property returns the value of the user-agent header sent by the browser to the server.
 * The value returned, contains information about the name, version and platform of the browser.
 * 
 * Example:
 * Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/85.0.4183.102 Safari/537.36 Edg/85.0.564.51
 * 
 * The order in which these browserName/version pairs are checked is very 
 * important since e.g. Edge Chromium (above) lists both Chrome and Edg, 
 * Edge being the last name listed (on macOS). In Chrome, however, the Edg
 * property will not be listed in the userAgent string. Safari will likely not
 * be listed on Windows. 
 * 
 * ## Other examples of userAgent strings
 *
 * ### Google Chrome on macOS Catalina
 * Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.66 Safari/537.36
 * 
 * ### Firefox on macOS Catalina
 * Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:82.0) Gecko/20100101 Firefox/82.0
 * 
 * ### Safari on macOS Catalina
 * Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0 Safari/605.1.15
 * 
 * ### Word app on macOS Catalina
 * Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko)
 * 
 * ### Teams app on macOS Catalina
 * Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Teams/1.3.00.28778 Chrome/80.0.3987.165 Electron/8.5.0 Safari/537.36
 */
export const getBrowserInfo = (userAgent: string = window.navigator.userAgent): BrowserInfo => {
  const result = {
    browser: Browser.UNKNOWN,
    version: ''
  }

  // List of browser names to search for in userAgent
  // NOTE order is important, see above comment
  const browserNames: BrowserName[] = [
    'Teams',
    'Edg',
    'Chrome',
    'Safari',
    'Firefox',
    'AppleWebKit'
  ]
  
  // Check each one in order
  for ( let i = 0; i < browserNames.length; i++ ) {
    const browserName = browserNames[i]

    // Search for the browser name and match version number (digits and dots)
    // (Safari puts version number after Version/)
    const versionRegExp = browserName === 'Safari' ?
      /Version\/([\d.]+)\sSafari/ :
      new RegExp(browserName + '/([\\d\\.]+)')

    const match = userAgent.match(versionRegExp)

    const version = match === null ? '' : match[1]
    if ( !!version ) {
      result.version = version
      result.browser = getBrowser(browserName)
      break
    }
  }

  return result
}