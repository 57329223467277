import React from 'react'
import { Icon, Modal, Transition, Accordion, Button, Search, SearchResultProps, SearchProps, Message } from 'semantic-ui-react'
import { MessageGetter } from '../../api/i18n'
import {  OribiApp, User } from '../../types'
import LogOutBtn from '../Auth/LogOutBtn'
import skolenheter from './skolor.json'

interface Skolenhet {
  name: string,
  lan: number,
  lansnamn: string,
  kommun: number,
  kommunnamn: string,
  skolenhetskod: number
}

type ResultRenderer = (props: SearchResultProps) => JSX.Element

type ActionType = 'CLEAN_QUERY' | 'START_SEARCH' | 'FINISH_SEARCH' | 'UPDATE_SELECTION'

interface Action {
  type: ActionType,
  query?: string
  results?: Option[]
  selection?: Option
}

interface Option {
  title: string
  description: string
  id: number
}

interface Props {
  onSuccess: (id: number) => void
  onFail: () => void
  app: OribiApp
  user: User
  validIds: number[]
  enableAppSwitcher: boolean
  i18n: MessageGetter
}

interface State {
  loading: boolean
  errorMessage?: string
  value: string,
  results: Option[]
  shakeToggle?: boolean // toggle true/false to trigger animation
  fetching?: boolean
}

// https://react.semantic-ui.com/modules/search/
export default class SchoolIdModal extends React.Component<Props, State> {
  timeout?: NodeJS.Timeout
  options: Option[]
  animation: 'shake' | 'pulse'

  constructor(props: Props) {
    super(props)

    this.options = []
    this.animation = 'shake'

    this.state = {
      loading: false,
      value: '',
      results: [],
      shakeToggle: true,
      fetching: true
    }
  }

  componentDidMount = () => {
    // Use local file first
    this.options = this.skolenhetToOptions(skolenheter)
  
    this.setState({
      fetching: false
    })
  }

  handleError = (message: string) => {
    if ( !!this.timeout ) clearTimeout(this.timeout)

    this.setState({
      errorMessage: message,
      fetching: false
    })
  }

  skolenhetToOptions = (result: Skolenhet[]): Option[] => {
    return result.reduce((options: Option[], current) => {
      const { skolenhetskod, name, kommunnamn, lansnamn } = current
      const isDuplicate = options.find(({ id }) => id === skolenhetskod)
      if ( isDuplicate ) return options

      options.push({
        id: skolenhetskod,
        title: name,
        description: `${kommunnamn}, ${lansnamn}`,
      })

      return options
    }, [])
  }

  updateSchoolsList = () => {
    this.options = []
    this.setState({ fetching: true })

    fetch('https://h2.oribi.se/api/h2/v1/skolor/all')
      .then(response => response.json())
      .then((result: Skolenhet[]) => {
        this.options = this.skolenhetToOptions(result)
        this.setState({ fetching: false })
      })
      .catch(({ message }) => {
        this.options = this.skolenhetToOptions(skolenheter)
        this.handleError(message)
      })
  }

  dispatch = (action: Action): void => {
    const initialState = {
      loading: false,
      results: [],
      value: '',
      errorMessage: ''
    }

    const { type, query, results, selection } = action

    switch ( type ) {
      case 'CLEAN_QUERY':
        this.setState(initialState)
        break
      case 'START_SEARCH':
        this.setState({
          loading: true,
          value: query || '',
          errorMessage: ''
        })
        break
      case 'FINISH_SEARCH':
        this.setState({
          loading: false,
          results: results || [],
          errorMessage: ''
        })
        break
      case 'UPDATE_SELECTION':
        if ( !selection ) break;
        const { id, title } = selection
        const success = this.props.validIds.includes(id)
        
        // Trigger animation
        this.animation = success ? 'pulse' : 'shake'
        this.setState(prevState => {
          const { shakeToggle } = prevState
          return {
            ...prevState,
            shakeToggle: !shakeToggle,
            value: title,
            // errorMessage: success ? '' : this.props.i18n('select_school_hint_invalid_selection')
          }
        })

        if ( success ) {
          this.timeout = setTimeout(() => {
            this.props.onSuccess(id)
          }, 400)
        }
        break
      default:
        this.handleError(`Unhandled dispatch event type ${type}.`)
        break
    } 
  }

  handleSearchChange = (_event: React.MouseEvent<HTMLElement, MouseEvent>, data: SearchProps) => {
    if ( !!this.timeout ) clearTimeout(this.timeout)

    const { value = '' } = data
    this.dispatch({ type: 'START_SEARCH', query: value })

    this.timeout = setTimeout(() => {
      if (value.length === 0) {
        this.dispatch({ type: 'CLEAN_QUERY' })
        return
      }

      const results = this.options.filter(option =>
        option.title.toLowerCase().includes( value.toLowerCase() )
      ).slice(0, 50)
      
      this.dispatch({
        type: 'FINISH_SEARCH',
        results
      })
    }, 300)
  }

  resultRenderer: ResultRenderer = ({ title, description }) => <div key='content' className='content'>
    <div className='title'>{title}</div>
    <div className='description'>{description}</div>
  </div>

  render = () => {
    const { app, i18n } = this.props
    const { loading, errorMessage, value, results, fetching, shakeToggle } = this.state

    const noResultsDescription = (value.length >= 1 && value.length < 3) ?
      i18n('select_school_hint_short_query') :
      ''

    return <Transition transitionOnMount animation='fade left'>
      <Modal className='center' open tabIndex='1'>
        <Modal.Header>
          <Icon name='university' />
          { i18n('select_school_header') }
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>{ i18n('select_school_description') }</p>
          </Modal.Description>
          <Transition animation={ this.animation } visible={ shakeToggle }>
            <Search
              onResultSelect={(_e, { result }) => {
                this.dispatch({
                  type: 'UPDATE_SELECTION',
                  selection: result as Option
                })
              }}
              onSearchChange={ this.handleSearchChange }
              resultRenderer={ this.resultRenderer }
              results={ results }
              loading={ loading || fetching }
              value={ value }
              fluid
              noResultsMessage={ i18n('select_school_hint_no_results') }
              noResultsDescription={ noResultsDescription }
              selectFirstResult
              minCharacters={ 3 }
            />
          </Transition>
          { errorMessage && 
            <Message error content={ errorMessage } />
          }
        </Modal.Content>
        <Modal.Content>
          <Accordion
            defaultActiveIndex={ undefined }
            panels={[
              {
                key: 'fetch',
                title: i18n('select_school_accordion_title'),
                content: {
                  content: (<>
                    <p>{ i18n('select_school_accordion_content') }</p>
                    <Button
                      loading={ fetching }
                      disabled={ fetching } 
                      content={ i18n('select_school_accordion_button_text') }
                      icon='refresh'
                      onClick={ this.updateSchoolsList }
                      fluid
                    />
                  </>)
                }
              },
              {
                key: 'extra',
                title: i18n('more_ways_to_register_header'),
                content: {
                  content: (<>
                    <p>{ i18n('more_ways_to_register_description', [app]) }</p>
                    <Button
                      style={{ marginTop: 15 }}
                      secondary
                      fluid
                      icon='unlock'
                      content={ i18n('register_app', [app]) }
                      onClick={() => this.props.onFail()}
                    />
                  </>)
                }
              }
            ]}
          />
        </Modal.Content>
        <Modal.Actions>
          <LogOutBtn i18n={ this.props.i18n } size='tiny' fluid />
          {/* <Button size='tiny' basic content='Logga ut' icon='sign out' fluid /> */}
        </Modal.Actions>
      </Modal>
    </Transition>
  }
}