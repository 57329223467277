import React from 'react'
import { Icon } from 'semantic-ui-react'
import { MessageGetter } from '../../api/i18n'
import { SpellcheckStatus, OrilangoResult, OrilangoResultType } from '.'

interface Props {
  i18n: MessageGetter
  status: SpellcheckStatus
  result: OrilangoResult
}

type IconColor = undefined|'red'|'orange'|'yellow'|'olive'|'green'|'teal'|'blue'|'violet'|'purple'|'pink'|'brown'|'grey'|'black'
const StatusBar = (props: Props) => {
  const { status, result, i18n } = props
  let icon: any = undefined
  let color: IconColor = undefined
  let text = ''
  let inverted = false
  let html = ''
  
  switch( status ) {
    case SpellcheckStatus.LOADING:
      icon = 'spinner'
      text = i18n('status_wait')
      color = undefined
      break
    case SpellcheckStatus.STOPPED:
      inverted = true
      text = result.message

      switch ( result.type ) {
        case OrilangoResultType.DONE:
          // text = 'Rättningen är klar'
          icon = 'thumbs up'
          color = 'green'
          break
        case OrilangoResultType.NOT_FOUND:
          icon = 'ellipsis horizontal'
          color = 'red'
          break
        case OrilangoResultType.CONFUSABLE_WORD:
          icon = 'clone'
          color = 'violet'
          break
        case OrilangoResultType.CHEK_PUNCTUATION:
          icon = 'text cursor'
          color = 'grey'
          break
        case OrilangoResultType.GRAMMAR:
          icon = 'edit'
          color = 'orange'
          break 
        case OrilangoResultType.SEPARATION:
          icon = 'linkify'
          color = 'orange'
          break
        default:
          icon = 'heart'
          color = 'teal'
          break
      }
      
      break
    case SpellcheckStatus.IDLE:
    default:
      html = i18n('status_click_next')
      icon = 'arrow right'
      inverted = true
      break
  }

  // Icon renders at 2x2em (28x28px)

  return <>
    <div className='status-icon'>
      <Icon
        loading={ SpellcheckStatus.LOADING === status }
        circular
        color={ color }
        inverted={ inverted }
        name={ icon }
        fitted
      />
    </div>
    <p className='status-text'>
      { !!html ? 
        <span dangerouslySetInnerHTML={{ __html: html }}></span> :
        <span>{ text }</span>
      }
    </p>
  </>
}

export default StatusBar