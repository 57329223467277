import React, { Component } from 'react'
import { OribiApp } from '../types'
import Startup from './Startup/'
import { Container, Grid, Button } from 'semantic-ui-react'

interface Props {}
interface State {
  app: OribiApp|undefined
}

export default class AppSwitcher extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      app: undefined
    }
  }

  render() {
    const { app } = this.state
    if ( app !== undefined ) return <Startup app={ app } enableAppSwitcher={ true } />

    const AppButton = ({ app }: { app: OribiApp }) => <Button
      fluid
      content={ app }
      onClick={() => {
        localStorage.oribiAppToRun = app
        this.setState({ app })
      }}
    />

    return (
      <Container>
        <h1 style={{ marginTop: 14 }}>Välj program</h1>

        <Grid columns={2} celled>
          <Grid.Row>
            <Grid.Column>
              <AppButton app={ OribiApp.STAVA_REX } />
            </Grid.Column>
            <Grid.Column>
            <AppButton app={ OribiApp.SPELLRIGHT } />
            </Grid.Column>
          </Grid.Row>
      
          <Grid.Row>
            <Grid.Column>
              <AppButton app={ OribiApp.VERITYSPELL } />
            </Grid.Column>
            <Grid.Column>
              <AppButton app={ OribiApp.STAVLET } />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <AppButton app={ OribiApp.SCHREIBREX } />
            </Grid.Column>
            <Grid.Column>
              
            </Grid.Column>
          </Grid.Row>

        </Grid>
      </Container>
    )
  }
}

export const AppSwitcherTrigger = () => {
  return <Button
    icon='exchange'
    content='Växla program'
    onClick={() => {
      delete localStorage.oribiAppToRun
      window.location.reload()
    }}
  />
}