import React from 'react'
import { Button, Accordion } from 'semantic-ui-react'
import { checkEddKey } from '../Auth/helpers'
import { OribiApp, User } from '../../types'
import PropertiesService, { LicenseType } from '../../api/storage'
import { MessageGetter } from '../../api/i18n'

interface Props {
  app: OribiApp
  user: User
  licenseKey: string
  propertiesService: PropertiesService
  i18n: MessageGetter
}
const DeactivateBtn = (props: Props) => {
  const { i18n } = props
  const [loading, setLoading] = React.useState(false)

  const deactivate = () => {
    const { licenseKey, user, app, propertiesService, i18n } = props
    setLoading(true)
    
    const updateServer = () => propertiesService.setAsync({
      license: LicenseType.UNKNOWN
    })

    const handleError = () => setLoading(false)

    checkEddKey(licenseKey, user, app, i18n, 'deactivate_license')
      .then(updateServer)
      .then(() => {
        window.location.reload()
      })
      .catch(handleError)
  }

  return <Accordion defaultActiveIndex={undefined} panels={[{
    key: 'deactivate',
    title: i18n('settings_license_deactivate_header'),
    content: {
      content: (<>
        <p>{ i18n('settings_license_deactivate_confirm') }</p>
        <Button
          fluid
          loading={ loading }
          negative
          content={ i18n('settings_license_deactivate_header') }
          icon='lock'
          onClick={ deactivate }
        />
      </>)
    }
  }]} />
}
export default DeactivateBtn