import React from 'react'
import { Button, CheckboxProps, Form, Icon, Image, Message, Modal, Transition } from 'semantic-ui-react'
import { MessageGetter } from '../../api/i18n'
import { getIconUrl } from '../../app'
import { HostType, OribiApp } from '../../types'

const getAppDescription = (app: OribiApp, i18n: MessageGetter, long = false): string => {
  switch (app) {
    case OribiApp.STAVA_REX:
      return !long ?
        'Ett avancerat rättstavningsprogram som rättar stavfel och grammatikfel på svenska.' :
        'Ett rättstavningsprogram som rättar stavfel och grammatikfel i svensk text, speciellt utvecklat med tanke på personer med läs- och skrivsvårigheter.'
    case OribiApp.SCHREIBREX:
      return !long ?
        'Ein Rechtschreibprogramm, das Rechtschreib- und Grammatikfehler in deutschen Texten korrigiert, insbesondere entwickelt für Menschen mit Lese- und Schreibschwierigkeiten.' :
        'Ein Rechtschreibprogramm, das Rechtschreib- und Grammatikfehler in deutschen Texten korrigiert, insbesondere entwickelt für Menschen mit Lese- und Schreibschwierigkeiten.'
    case OribiApp.STAVLET:
      return !long ?
        'StavLet er et dansk retskrivningsprogram udviklet specielt til personer med læse- og skrivevanskeligheder.' :
        'StavLet er et dansk retskrivningsprogram udviklet specielt til personer med læse- og skrivevanskeligheder.'
    case OribiApp.VERITYSPELL:
      return !long ?
        'VeritySpell is a spellchecker and grammarchecker for native English speakers.' :
        'VeritySpell is a spellchecker and grammarchecker for native English speakers. It is specially designed for people with reading and writing difficulties, like dyslexia.'
    case OribiApp.SPELLRIGHT:
      return i18n(!long ? 'onboarding_description_short_spellright' : 'onboarding_description_spellright')
    default:
      return ''
  }
}

interface Props {
  app: OribiApp
  host: HostType
  handleClick: () => void
  i18n: MessageGetter
}
const OnboardingModal = (props: Props) => {
  const { app, host, i18n, handleClick } = props

  const isOneNote = host === HostType.OneNote
  const [termsAccepted, acceptTerms] = React.useState(false)
  
  // setVisible(true)

  let btnDisabled = false
  if ( isOneNote && !termsAccepted ) btnDisabled = true

  const handleCheckboxChange = (_event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    acceptTerms(!!data.checked)
  }

  return (
    <Transition transitionOnMount animation='fade up'>
      <Modal open dimmer='blurring' id='onboarding' tabIndex='1'>
        <Modal.Header className='branded'>
          <Image src={ getIconUrl(app, { transparent: true }) } />
          { i18n('onboarding_title', [app]) }
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <p><strong>{ getAppDescription(app, i18n) }</strong></p>
            <p style={{ clear: 'both' }}>{ i18n('empty_doc_hint_content', [app]) }</p>
            { isOneNote && <Message warning>
              <Message.Header>{ i18n('onboarding_disclaimer_header', [host]) }</Message.Header>
              <Message.Content>
                <p>{ i18n('onboarding_disclaimer_text', [host, app]) }</p>
              </Message.Content>
            </Message> }
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          { isOneNote && <Form style={{ textAlign: 'left', marginBottom: 20 }}>
            <Form.Checkbox
              onChange={ handleCheckboxChange }
              checked={ termsAccepted }
              label={ i18n('onboarding_disclaimer_label', [host, app]) }
            /> 
          </Form> }
          <Button disabled={ btnDisabled } onClick={ handleClick } primary>
            { i18n('onboarding_get_started') } <Icon name='chevron right' />
          </Button>
        </Modal.Actions>
      </Modal>
    </Transition>
  )
}

export default OnboardingModal