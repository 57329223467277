import { User, OribiApp } from '../../types'
import { LicenseType } from '../../api/storage'
import { getEddIds } from '../../app'
import { MessageGetter } from '../../api/i18n'

interface GraphResponse {
  success: boolean
  errorMessage?: string
  user?: User
}

export const requestGraphUser = (accessToken: string): Promise<GraphResponse> => {
  const graphResponse: GraphResponse = {
    success: false
  }
  
  return new Promise(resolve => {
    if ( !accessToken ) return resolve(graphResponse)

    const headers = new Headers()
    const bearer = `Bearer ${ accessToken }`
    headers.append('Authorization', bearer)

    const options = {
      method: 'GET',
      headers: headers
    }
    const graphEndpoint = 'https://graph.microsoft.com/v1.0/me'

    fetch(graphEndpoint, options)
      .then(response => response.json())
      .then(result => {
        if ( result.error ) {
          graphResponse.errorMessage = result.error.message
          resolve(graphResponse)
        } else {
          graphResponse.success = true
          graphResponse.user = {
            email: result.userPrincipalName,
            id: result.id
          }
        }
        resolve(graphResponse)
      })
      .catch((error) => {
        const { message } = error
        graphResponse.errorMessage = message || 'Graph call error'
        resolve(graphResponse)
      })
  })
}

interface HabitatResultApp {
  name: string // 'OribiSpeak'|'StavaRex'|'SpellRight'|'StavLet'|'SchreibRex'|'VeritySpell'
  id: 1|2|3|4|5
  licensetype: 'skola'|'unknown'
  licensecolor: 'Grey'|'Black'|'White'
}
interface HabitatResult {
  name: string
  oribi_avtalsid: number
  start_quarter: 0|1|2|3|4
  skolenhetsid: number[]
  apps: HabitatResultApp[]
}
type HabitatResultsList = [HabitatResult?]
type DomainChecker =
  (domain: string, app: OribiApp, i18n: MessageGetter, schoolId?: number) =>
    Promise<LicenseInfo>

interface LicenseInfo {
  type: LicenseType,
  validSchoolIds?: number[],
  message?: string
}

const checkDomain: DomainChecker = (domain, app, i18n, schoolId) => new Promise(async resolve => {
  const reservedDomains = /^(?:gmail|hotmail|live|msn|outlook|passport|yahoo)\.(?:com|se)$/
  if ( reservedDomains.test(domain) ) return resolve({ type: LicenseType.UNAUTHORIZED })

  if ( domain === 'oribi.se' ) return resolve({
    type: LicenseType.FREE,
    message: i18n('app_free_for_employees', [app])
  })

  const url = `https://h2.oribi.se/api/h2/v1/avtal/domain/${domain}`
  try {
    const resposne = await fetch(url)
    const result: HabitatResultsList = await resposne.json()
    const [habitatResult] = result
    if ( !habitatResult ) return resolve({ type: LicenseType.UNAUTHORIZED })

    const { apps } = habitatResult
    const getAppFromHabitatName: (name: string) => OribiApp|null = (name) => {
      switch ( name ) {
        case 'StavaRex':
          return OribiApp.STAVA_REX
        case 'SpellRight':
          return OribiApp.SPELLRIGHT
        case 'StavLet':
          return OribiApp.STAVLET
        case 'SchreibRex':
          return OribiApp.SCHREIBREX
        case 'VeritySpell':
          return OribiApp.VERITYSPELL
        default:
          return null
      }
    }

    const licensedApp = apps.find(({ name }) => 
      getAppFromHabitatName(name) === app
    )
    if ( !licensedApp ) return resolve({ type: LicenseType.UNAUTHORIZED })

    const { licensecolor } = licensedApp
    const licenseInfo: LicenseInfo = {
      type: LicenseType.UNAUTHORIZED
    }

    licenseInfo.message = i18n('license_valid_for_x', [habitatResult.name])
    if ( licensecolor === 'White' ) {
      licenseInfo.type = LicenseType.DOMAIN
    } else if ( licensecolor === 'Grey' ) {
      if ( !!schoolId && habitatResult.skolenhetsid.includes(schoolId) ) {
        licenseInfo.type = LicenseType.SCHOOL
      } else {
        licenseInfo.type = LicenseType.GREYLIST
        licenseInfo.validSchoolIds = habitatResult.skolenhetsid
      }
    }

    return resolve(licenseInfo)
  } catch(error) {
    console.warn(error)
    // Run in FREE mode, should anything go wrong (e.g. server unavailable)
    return resolve({
      type: LicenseType.FREE,
      message: i18n('license_server_unavailable', [error.message])
    })
  }
})

type EddActionType = 'check_license'|'activate_license'|'deactivate_license'
interface EDDResult {
  success: boolean
  license: 'valid'|'invalid'|'disabled'|'deactivated'
  item_id: false|number
  item_name: string // Empty string if no product matches item_id
  checksum: string
  error?: 'disabled'|'no_activations_left'|'revoked'|'expired'
  expires?: 'string'
  site_count?: number
  license_limit?: number
  activations_left?: number
  payment_id?: number
  customer_name?: string
  customer_email?: string
}
interface EddCheckerResult {
  success: boolean
  serverAvailable: boolean
  errorMessage?: string
  successMessage?: string
  // expires?: string
}
type EddChecker =
  (key: string, user: User, app: OribiApp, i18n: MessageGetter, action?: EddActionType) =>
    Promise<EddCheckerResult>

export const checkEddKey: EddChecker = (key, user, app, i18n, action = 'check_license') => {
  return new Promise(async (resolve) => {
    const { email } = user
    const result: EddCheckerResult = {
      success: false,
      serverAvailable: true
    }

    // The app may have multiple corresponding IDs in the shop, so let's check
    // all of them.
    const eddIds = getEddIds(app)
    checkloop: for ( const id of eddIds ) {
      try {
        const url = 'https://oribisoftware.com/website/' +
          '?edd_action=' + action +
          '&item_id=' + id +
          '&license=' + encodeURIComponent( key ) +
          '&url=' + encodeURIComponent( email )
          
        const eddResult: EDDResult = await fetch(url).then(resp => resp.json())
        if ( eddResult.license === 'valid' ) {
          result.success = true
          if ( eddResult.expires ) {
            result.successMessage = i18n('license_key_valid_until_x', [eddResult.expires])
          }
          break // break checkloop
        } else if ( eddResult.license === 'disabled' ) {
          result.errorMessage = i18n('license_key_disabled')
          break // break checkloop
        } else if ( eddResult.license === 'deactivated' ) { // && eddResult.success ?
          result.success = true
          // result.successMessage = 'Nyckeln kunde avaktiveras utan problem.'
        } else {
          switch ( eddResult.error ) {
            case 'no_activations_left':
              result.errorMessage = i18n('license_key_no_activations_left')
              break checkloop
            case 'disabled':
            case 'revoked':
            case 'expired':
              result.errorMessage = i18n('license_key_disabled')
              break checkloop
            default:
              result.errorMessage = i18n('license_key_invalid')
              break // break switch statement, not checkloop
          }
          continue // continue checkloop
        }
      } catch(error) {
        // No response
        result.serverAvailable = false
        result.errorMessage = i18n('license_server_unavailable', [error.message])
        break // break checkloop
      }
    }

    return resolve(result)
  })
}

export interface LicenseCheckerProps {
  user: User
  licenseType: LicenseType
  app: OribiApp
  trialStart?: string
  schoolId?: number
  handleStatusChange?: (statusText: string) => void
  handleTrialExpired?: () => void
  licenseKey?: string
  i18n: MessageGetter
}
type LicenseChecker = (props: LicenseCheckerProps) => Promise<LicenseInfo>

export const checkUserLicense: LicenseChecker = (props) => {
  const {user, app, handleStatusChange, trialStart, schoolId, handleTrialExpired, i18n} = props
  let { licenseType } = props
  let licenseInfo: LicenseInfo
    // this.setState({ statusText: `Validerar ${domain}...` })

  return new Promise(async resolve => {
    const domain = user.email.split('@')[1]

    if ( !!handleStatusChange ) handleStatusChange(i18n('status_validating_license'))
    
    switch ( licenseType ) {
      // User never authorized to use app
      case LicenseType.UNKNOWN:
      case LicenseType.FREE:
      case LicenseType.UNAUTHORIZED:
      case LicenseType.GREYLIST:
      case LicenseType.SCHOOL:
      case LicenseType.DOMAIN:
        licenseInfo = await checkDomain(domain, app, i18n, schoolId)
        resolve(licenseInfo)
        // if UNAUTHORIZED start trial automatically?
        break
      // User has started trial
      case LicenseType.TRIAL:
        if ( !trialStart ) return resolve({
          type: LicenseType.TRIAL,
          message: i18n('trial_active')
        })
        
        const days = 24 * 60 * 60 * 1000 // 1 day in ms
        const trialLimit = 30 * days // 30 days
        const trialStartTime = new Date(trialStart).getTime()
        const trialEndTime = trialStartTime + trialLimit
        const nowTime = Date.now()
        const timeLeft = trialEndTime - nowTime

        if ( timeLeft > 0 ) {
          const daysLeft = Math.round(timeLeft / days)
          let daysLeftString = i18n('in_x_days', [daysLeft.toString()])

          if ( daysLeft === 0 ) {
            daysLeftString = i18n('today')
          } else if ( daysLeft === 1 ) {
            daysLeftString = i18n('tomorrow')
          } else if ( daysLeft === 2 ) {
            daysLeftString = i18n('day_after_tomorrow')
          }
          
          resolve({
            type: LicenseType.TRIAL,
            message: i18n('trial_end_x', [daysLeftString])
          })
        } else {
          if ( !!handleTrialExpired ) handleTrialExpired()
          licenseInfo = await checkDomain(domain, app, i18n)
          resolve(licenseInfo)
        }
        break
      case LicenseType.LICENSE_KEY:
        // Re-check license key with EDD
        const { licenseKey } = props

        if ( !licenseKey ) {
          licenseInfo = await checkDomain(domain, app, i18n)
          resolve(licenseInfo)
        } else {
          const eddCheckResult = await checkEddKey(licenseKey, user, app, i18n)
          const { success, successMessage, serverAvailable, errorMessage } = eddCheckResult
          if ( success ) {
            resolve({
              type: LicenseType.LICENSE_KEY,
              message: successMessage
            })
          } else if ( !serverAvailable ) {
            resolve({
              type: LicenseType.FREE,
              message: errorMessage
            })
          } else {
            licenseInfo = await checkDomain(domain, app, i18n)
            resolve(licenseInfo)
          }
        }
        break
      // case LicenseType.SCHOOL:
      //   licenseInfo = await checkDomain(domain, app, schoolId)
      //   resolve(licenseInfo)
      //   break
      //   onStatusChange(`Kontrollerar demoversion...`)
      //   // 1.  Check if trial is still active
      //   //     If trial over, set LicenseType.UNAUTHORIZED and return checkUserLicense(user, LicenseType.UNAUTHORIZED)
      //   //     If trial OK, resolve LicenseType.TRIAL
      //   licenseType = LicenseType.TRIAL
      //   break
      // // User has domain
      // case LicenseType.DOMAIN:
      //   onStatusChange(`Validerar ${ domain }...`)
      //   // 1.  If domain still OK, resolve LicenseType.DOMAIN
      //   //     If not, set LicenseType.UNAUTHORIZED and return checkUserLicense(user, LicenseType.UNAUTHORIZED)
      //   licenseType = LicenseType.DOMAIN
      //   break
      // case LicenseType.LICENSE_KEY:
      //   onStatusChange(`Kontrollerar licensnyckel...`)
      //   licenseType = LicenseType.LICENSE_KEY
      //   break
      // case LicenseType.GREYLIST:
      //   licenseType = LicenseType.GREYLIST
      default:
        resolve({ type: licenseType })
        break
    }
  })
}