/**
 * Make testing easier by specifying EXACTLY which types we need.
 */

export enum OribiApp {
  STAVA_REX = 'Stava Rex',
  SPELLRIGHT = 'SpellRight',
  VERITYSPELL = 'VeritySpell',
  STAVLET = 'StavLet',
  SCHREIBREX = 'SchreibRex'
}

export enum HostType {
  Word = 'Word',
  OneNote = 'OneNote',
  Other = 'Unsupported editor',
  Unknown = 'Host not yet determined'
}

export enum PlatformType {
  PC = 'PC',
  OfficeOnline = 'OfficeOnline',
  Mac = 'Mac',
  iOS = 'iOS',
  Android = 'Android',
  Universal = 'Universal',
  Unknown = 'Platform not yet determined'
}

export interface OfficeInfo {
  host: HostType
  platform: PlatformType
}

export interface User {
  email: string
  id: string
}

export default {}