
import React from 'react'
import { Message } from 'semantic-ui-react'
import { MessageGetter } from '../../api/i18n'
import { OribiApp } from '../../types'

interface Props {
  i18n: MessageGetter
  app: OribiApp
}

const EmptyDocMessage = (props: Props) => <Message info>
  <Message.Header style={{ marginBottom: 8 }} content={ props.i18n('empty_doc_hint_header') } />
  <Message.Content>
    <p>{ props.i18n('empty_doc_hint_content', [props.app]) }</p>
  </Message.Content>
</Message>

export default EmptyDocMessage