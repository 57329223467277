
import React from 'react'
import { Icon, Modal, Transition, Message, Form, Button, Accordion } from 'semantic-ui-react'
import Translator, { MessageGetter } from '../../api/i18n'
import {  OribiApp, User } from '../../types'
import { getPurchaseLink } from '../../app'
import { checkEddKey, checkUserLicense } from '../Auth/helpers'
import PropertiesService, { LicenseType } from '../../api/storage'
import LogOutBtn from '../Auth/LogOutBtn'

interface Props {
  onSuccess: (key: string, message: string) => void
  onTrialActivated: (message: string) => void
  app: OribiApp
  user: User
  trialExpired: boolean
  trialStart?: string
  licenseKey?: string
  translator: Translator
  propertiesService: PropertiesService
}
interface State {
  loading: boolean
  key: string
  errorMessage?: string
}

export default class LicenseKeyModal extends React.Component<Props, State> {
  i18n: MessageGetter

  constructor(props: Props) {
    super(props)

    this.i18n = props.translator._

    this.state = {
      loading: false,
      key: props.licenseKey || ''
    }
  }

  handleTrialButtonClick = () => {
    const { propertiesService, trialStart, user, app } = this.props
    if ( !trialStart ) {
      const trial_start = new Date().toJSON()
      propertiesService.set({ trial_start })
    }
    
    checkUserLicense({
      user, app, trialStart,
      licenseType: LicenseType.TRIAL,
      i18n: this.i18n
    }).then(({ type, message }) => {
      if ( !!message ) this.props.onTrialActivated(message)
      this.props.propertiesService.set({ license: type })
    })
  }

  checkLicense = async () => {
    this.setState({
      loading: true,
      errorMessage: ''
    })

    const { key } = this.state
    const { user } = this.props
    const { i18n } = this

    const eddActivateResult = await checkEddKey(key, user, this.props.app, i18n, 'activate_license')

    const { success, successMessage, errorMessage } = eddActivateResult

    this.setState({ loading: false })
    if ( success ) {
      this.props.onSuccess(key, successMessage || '')
    } else {
      // Set error message
      this.setState({ errorMessage })
    }
  }

  render = () => {
    const { app, trialExpired, trialStart, translator } = this.props
    const { loading, errorMessage, key } = this.state
    
    const { lang, _: i18n } = translator
    
    return (
      <Transition transitionOnMount animation='fade left'>
        <Modal className='center' open tabIndex='1'>
          <Modal.Header>
            <Icon name='key' />
            { i18n('register_app', [app]) }
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>{ i18n('my_account_license_description') } <a target="_blank" rel='noopener noreferrer' href={ i18n('my_account_url') }>{ i18n('my_account') }</a>.</p>
            </Modal.Description>
            
          </Modal.Content>
          <Modal.Actions>
            <Form onSubmit={ this.checkLicense }>
              <Form.Input
                focus={ true }
                value={ key }
                loading={ loading }
                disabled={ loading }
                fluid
                placeholder={ i18n('your_license_key') }
                action={{
                  primary: true,
                  disabled: !key.length || loading,
                  icon: 'unlock'
                }}
                onChange={(_e: any, { value }: { value: string }) => {
                  this.setState({ key: value })
                }}
              />
            { errorMessage &&
              <Message error content={ errorMessage } />
            }
            </Form>
          </Modal.Actions>
          <Modal.Content>
            <Accordion defaultActiveIndex={ trialExpired || !!trialStart ? 0 : 1 } panels={[
                {
                  key: 'purchase',
                  title: i18n('purchase_license_key'),
                  content: {
                    content: (<>
                      <p>{ i18n('purchase_app_at', [app, 'Oribi Web Store']) }</p>
                      <Button
                        style={{ marginTop: 15 }}
                        fluid
                        secondary
                        icon='cart'
                        content={ i18n('add_to_cart') }
                        target='_blank'
                        rel='noopener noreferrer'
                        href={ getPurchaseLink(app, lang) }
                      />
                    </>)
                  }
                },
                {
                  key: 'trial',
                  title: i18n('trial_header'),
                  content: {
                    content: (<>
                      { trialExpired ?
                        <p>Trial over.</p> :
                        <>
                          <p>{ i18n('trial_description', [app]) }</p>
                          <TrialButton
                            content={ i18n(!trialStart ? 'start_trial' : 'continue_trial') }
                            onClick={ () => this.handleTrialButtonClick() }
                          />
                      </>
                      }
                    </>)
                  }
                },
                {
                  key: 'help',
                  title: i18n('support_header'),
                  content: {
                    content: (<>
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        { i18n('support_description', [app]) }
                      </p>
                      <p>
                        <a href={ i18n('support_faq_link') } target='_blank' rel='noopener noreferrer'><Icon name='question' />FAQ</a>
                        &nbsp;&bull;&nbsp;
                        <a href={ i18n('support_link') } target='_blank' rel='noopener noreferrer'><Icon name='life ring' />Support</a>
                      </p>
                    </>)
                  }
                }
              ]} />
          </Modal.Content>
          <Modal.Actions>
            <LogOutBtn i18n={ this.i18n } size='tiny' fluid />
          </Modal.Actions>
        </Modal>
      </Transition>
    )
  }
}

interface TrialButtonProps {
  content: string
  onClick: () => void
}

const TrialButton = (props: TrialButtonProps) => {
  const { content, onClick } = props
  const [loading, setLoading] = React.useState(false)

  const handleClick = () => {
    setLoading(true)
    onClick()
  }

  return <Button
    style={{ marginTop: 15 }}
    fluid
    secondary
    loading={ loading }
    content={ content }
    onClick={ handleClick }
  />
}