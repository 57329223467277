import { HostType } from '../../types'

export type HTMLString = string

export interface OneNoteParagraph {
  text: string, // Representation of the paragraph text, with an appended newline (\n)
  id: string,
  item: OneNote.Paragraph,
  html: HTMLString,
  outlineItemId: string,
  startOffset: number,
  endOffset: number
}

export interface OneNoteOutLine {
  id: string
  paragraphs: OneNoteParagraph[]
  startOffset: number
  endOffset: number
}

export interface DocumentBody {
  text: string
  paragraphs?: OneNoteParagraph[]
  outlines?: OneNoteOutLine[]
}

let activeOneNoteParagraph: OneNoteParagraph|undefined = undefined
let selectionStart: number = 0
let selectionLength: number = 0

export const getOneNoteOutlines = async (): Promise<OneNoteOutLine[]> => {
  await Office.onReady()
    const OneNote = window.OneNote
    
    return OneNote.run(async (context) => {
      const page = context.application.getActivePageOrNull()
      
      page.load('contents')
      await context.sync()

      const outlineItems = page.contents.items.filter(item =>
        item.type === OneNote.PageContentType.outline
      )
      
      const outlines = []
      let startOffset = 0

      for ( const outlineItem of outlineItems ) {
        outlineItem.load('outline')
        await context.sync()
        
        const outline: OneNoteOutLine = {
          id: outlineItem.id,
          paragraphs: [],
          startOffset: -1,
          endOffset: -1
        }

        outlineItem.outline.load('paragraphs')
        await context.sync()

        const richTextParagraphs = outlineItem.outline.paragraphs.items
          .filter(outlineItem => outlineItem.type === OneNote.ParagraphType.richText)
        

        for ( const paragraphItem of richTextParagraphs ) {
          paragraphItem.load('id,richText/text')
          await context.sync()

          const html = paragraphItem.richText.getHtml()
          await context.sync()
          
          let { text } = paragraphItem.richText
          text += '\n'
          // text = String.fromCharCode(182) + text // -> ¶text

          const paragraph: OneNoteParagraph = {
            text: text,
            html: html.value,
            id: paragraphItem.id,
            item: paragraphItem,
            outlineItemId: outlineItem.id,
            startOffset: startOffset,
            endOffset: startOffset + text.length
          }

          outline.startOffset = startOffset
          outline.endOffset = startOffset + text.length

          startOffset += text.length

          outline.paragraphs.push(paragraph) 
        }
        outline.startOffset = outline.paragraphs[0].startOffset
        const lastParagraph = outline.paragraphs[outline.paragraphs.length - 1]
        outline.endOffset = lastParagraph.endOffset

        outlines.push(outline)
      }
      
      return outlines
    })
}

export async function getOneNoteRichTextParagraphs():Promise<OneNoteParagraph[]> {
  return new Promise(async resolve => {
    await Office.onReady()
    const OneNote = window.OneNote
    
    OneNote.run(async context => {
      const page = context.application.getActivePageOrNull()
      
      page.load('contents')
      await context.sync()

      const outlineItems = page.contents.items.filter(item =>
        item.type === OneNote.PageContentType.outline
      )
      
      const paragraphs = []
      let startOffset = 0

      for ( const outlineItem of outlineItems ) {
        outlineItem.load('outline')
        await context.sync()

        outlineItem.outline.load('paragraphs')
        await context.sync()

        const richTextParagraphs = outlineItem.outline.paragraphs.items
          .filter(outlineItem => outlineItem.type === OneNote.ParagraphType.richText)
        
        for ( const paragraphItem of richTextParagraphs ) {
          paragraphItem.load('id,richText/text')
          await context.sync()

          const html = paragraphItem.richText.getHtml()
          await context.sync()
          
          let { text } = paragraphItem.richText
          text += '\n'
          // text = String.fromCharCode(182) + text // -> ¶text

          const paragraph: OneNoteParagraph = {
            text: text,
            html: html.value,
            id: paragraphItem.id,
            item: paragraphItem,
            outlineItemId: outlineItem.id,
            startOffset: startOffset,
            endOffset: startOffset + text.length
          }

          startOffset += text.length
          paragraphs.push(paragraph)
        }
      }
      
      return resolve(paragraphs)
    })
  })
}

export function getDocumentBody(host: HostType): Promise<DocumentBody> {
  return new Promise(async resolve => {
    switch ( host ) {
      case HostType.Word:
        await Office.onReady()
        const Word = window.Word

        Word.run(async context => {
          const body = context.document.body
          context.load(body, 'text')

          await context.sync()
          return resolve({
            text: body.text
          })
        })
        break
      case HostType.OneNote:
        const paragraphs = await getOneNoteRichTextParagraphs()
        const outlines = await getOneNoteOutlines()
        const text = paragraphs.map(p => p.text).join('')
        resolve({
          text,
          paragraphs,
          outlines
        })
        break
      default: return resolve({
        text: ''
      })
    }
    
  })
}

export const setSelection = async (host: HostType, start: number, length: number) => {
  switch ( host ) {
    case HostType.Word:
      await setSelectionInWord(start, length)
      break
    case HostType.OneNote:
      await setSelectionInOneNote(start, length)
      break
    default: throw new Error(`Can't set selection in ${host}`)
  }
}

const setSelectionInWord = async (startOffset: number, length: number) => {
  await Office.onReady()
  const documentBody = await getDocumentBody(HostType.Word)
  const selectionText = documentBody.text
    .substring(startOffset, startOffset + length)
    .replace('\r', '\n') // TODO: Improve logic?

  if ( !selectionText ) return
  const Word = window.Word

  return Word.run(async context => {
    const results = context.document.body.search(selectionText, {
      matchCase: false
    })

    const body = context.document.body

    results.load('items')
    body.load('text')
    
    await context.sync()

    const { text } = body
    let itemIndex = 0

    // If document text contains more than one occurrence of selectionText,
    // find out which one to select 
    const search = RegExp(selectionText, 'gi')
    const matches = text.matchAll(search)

    let match = matches.next()
    while ( !match.done ) {
      const { index } = match.value
      if ( index === startOffset ) break; // TODO: add margins?
      
      match = matches.next()
      itemIndex++
    }

    const item = results.items[itemIndex]
    item.select(Word.SelectionMode.select)
    
    return context.sync()
  })
}

// In OneNote, we can't really "set selection" since the API provides us with no
// such feature. We can however replace an entire paragraph, so let's at least
// cache the paragraph in question so that we know which one to replace later on.
const setSelectionInOneNote = async (start: number, length: number) => {
  await Office.onReady()
  const documentBody = await getDocumentBody(HostType.OneNote)
  const { paragraphs } = documentBody

  if ( !paragraphs || !paragraphs.length ) return;

  const end = start + length
  activeOneNoteParagraph = paragraphs.find(({ startOffset, endOffset }) =>
    startOffset <= start && endOffset >= end
  )
  selectionStart = start
  selectionLength = length
}

export const getOneNoteOutline = async (startOffset: number) => {
  await Office.onReady()
  const documentBody = await getDocumentBody(HostType.OneNote)
  const { paragraphs } = documentBody
  console.log(paragraphs)
}

// const getItemAt = index => arr.find(item => index >= item.a && index < item.b)
export function replaceOneNoteParagraph(
  paragraphObj: OneNoteParagraph,
  newHTML: HTMLString
):Promise<boolean> {
  if ( !paragraphObj || !newHTML ) return Promise.resolve(false)

  return new Promise(async resolve => {
    await Office.onReady()
    const OneNote = window.OneNote

    OneNote.run(async context => {
      const page = context.application.getActivePage()
      const { contents } = page

      const item = contents.getItem(paragraphObj.outlineItemId)
      await context.sync()

      if ( !item ) return resolve(false)
      
      item.load('outline')
      await context.sync()

      item.outline.load('paragraphs')
      await context.sync()
      const { items } = item.outline.paragraphs
      
      const paragraph = items.find(item => item.id === paragraphObj.id)
      if ( !paragraph ) return resolve(false)

      paragraph.insertRichTextAsSibling(OneNote.InsertLocation.after, newHTML)
      paragraph.delete()

      context.sync()
      resolve(true)
    })
  })
}

type OribiSelectionMode = 'start'|'end'|'select'
export const replaceSelection = async (host: HostType, newText: string, selectionMode: OribiSelectionMode = 'end') => {
  switch ( host ) {
    case HostType.Word:
      await replaceSelectionInWord(newText, selectionMode)
      break
    case HostType.OneNote:
      await replaceSelectionInOneNote(newText)
      break
    default: throw new Error(`Can't replace selection in ${host}`)
  }
}

const replaceSelectionInWord = async (newText: string, selectionMode: OribiSelectionMode) => {
  await Office.onReady()
  const Word = window.Word
  
  return Word.run(context => {
    let wordSelectionMode: Word.SelectionMode = Word.SelectionMode.end
    if ( selectionMode === 'start' ) {
      wordSelectionMode = Word.SelectionMode.start
    } else if ( selectionMode === 'select' ) {
      wordSelectionMode = Word.SelectionMode.select
    }

    context.document.getSelection()
      .insertText(newText, Word.InsertLocation.replace)
      .select(wordSelectionMode)
    
    return context.sync()
  })
}

const replaceSelectionInOneNote = async (newText: string) => {
  if ( !activeOneNoteParagraph ) return;

  const { text, startOffset, outlineItemId, id } = activeOneNoteParagraph

  const start = selectionStart - startOffset
  const end = start + selectionLength

  const newParagraphText = text.substring(0, start) +
    newText +
    text.substring(end)
  
  await Office.onReady()
  const OneNote = window.OneNote

  await new Promise(resolve => {
    OneNote.run(async context => {
      const page = context.application.getActivePage()
      const { contents } = page
  
      const item = contents.getItem(outlineItemId)
      await context.sync()
      if ( !item ) return;
      
      item.load('outline')
      await context.sync()
  
      item.outline.load('paragraphs')
      await context.sync()
      const { items } = item.outline.paragraphs
      
      const paragraph = items.find(item => item.id === id)
      if ( !paragraph ) return;
  
      paragraph.insertHtmlAsSibling(OneNote.InsertLocation.before, newParagraphText)
      paragraph.delete()
  
      return context.sync().then(resolve)
    })
  })
}

export function selectBeginning(host: HostType): Promise<void> {
  if ( host !== HostType.Word ) return Promise.resolve()

  return window.Word.run(context => {
    context.document.body.select('Start')
    return context.sync()
  })
}

interface DocumentSelection {
  selectionStart: number
  selectionLength: number
}
export function getDocumentSelection(host: HostType): Promise<DocumentSelection|undefined> {
  if ( host !== HostType.Word ) return Promise.resolve(undefined)

  return window.Word.run(context => {  
    const doc = context.document
    const getSel = () => doc.getSelection()

    const origin = getSel()
    origin.select('Start')
  
    const start = getSel()
    doc.body.getRange('Start').select('Start') // BUG: Requires WordApi 1.3
  
    const first = getSel()
    const rangeToCaret = start.expandTo(first) // BUG: Doesn't work in header/footer
  
    origin.select('Select')
    rangeToCaret.load('text')
    origin.load('text')
  
    return context.sync().then(() => ({
      selectionStart: rangeToCaret.text.length,
      selectionLength: origin.text.length
    }))
  })
}