import { SoftError, ErrorType } from "../Errors"

export function getOribiSpeakId(): Promise<string> {
  // Published Oribi Speak IDs
  const oribiSpeakIds = [
    'epmjcijfcpnhbglfecjdngdhgiobhceb', // Chrome Internal (published)
    'jmhaloheodngkciligplhbkgjnpgfhoo', // Chrome
    'ebcncfcpjdnnkacdndpkkfganfdoglaf' // Edge
  ]
  let installedId = ''

  // Promise wrapper for chrome.runtime.sendMessage callback
  const checkAsync = (id: string) => new Promise(resolve =>
    chrome.runtime.sendMessage(id, 'is_os_installed', resolve)
  )
  
  return new Promise(async resolve => {
    for ( const id of oribiSpeakIds ) {
      try {
        const response = await checkAsync(id)

        // No installed extension with this ID (no receiving end), check next
        if ( chrome.runtime.lastError ) continue

        if ( response === true ) {
          installedId = id
          break
        }
      } catch(_error) {
        // console.log(_error.message)
      }
    }
    
    resolve(installedId)
  })
}

type Speaker = (text: string, voice: SpeechSynthesisVoice) => Promise<boolean>
export const speak: Speaker = (text, voice) => new Promise(resolve => {
  const { speechSynthesis } = window
  if ( !speechSynthesis ) return resolve(false)
  
  if ( speechSynthesis.speaking ) speechSynthesis.cancel()
  
  const utterance = new SpeechSynthesisUtterance(text)
  utterance.voice = voice
  utterance.onend = () => resolve(true)

  speechSynthesis.speak(utterance)
})

export async function speakLocally(text: string, lang: string) {
  const { speechSynthesis } = window
  if ( !speechSynthesis ) throw new SoftError(ErrorType.TTS_UNAVAILABLE)

  let voices = speechSynthesis.getVoices()

  // Voice array tends to be empty at first request so wait and try again
  if ( !voices.length ) {
    await new Promise(resolve => setTimeout(resolve, 100))
    voices = speechSynthesis.getVoices()
  }

  const voiceInLang = voices.find(voice => voice.lang.split('-')[0] === lang)

  if ( !voiceInLang ) throw new SoftError(ErrorType.TTS_LANG_UNAVAILABE)
  
  const utterance = new SpeechSynthesisUtterance(text)
  utterance.voice = voiceInLang

  if ( speechSynthesis.speaking ) speechSynthesis.cancel()
  
  speechSynthesis.speak(utterance)
}

interface OribiSpeakOptions {
  extensionId: string,
  text: string,
  lang: string,
  onError: () => void
}
export function oribiSpeak(options: OribiSpeakOptions) {
  const { extensionId, text, lang, onError } = options
  const request = 'speak_text'
  const message = { request, text, lang }

  try {
    chrome.runtime.sendMessage(extensionId, message, response => {
      if ( chrome.runtime.lastError || !response ) return onError()
    })
  } catch(_error) {
    return onError()
  }
}

export enum TTSProvider {
  LOCAL = 'window.speechSynthesis',
  ORIBI_SPEAK = 'Oribi Speak'
}