import React from 'react'
import { MessageGetter } from '../../api/i18n'
import { SpellcheckSuggestion } from '.'
import { List, Container, Button } from 'semantic-ui-react'
import { OribiApp } from '../../types'
import EmptyDocMessage from './EmptyDocMessage'
import { TTSProvider } from '../TTS/api'
import { hasDictionary } from '../../app'

interface SuggestionListItemProps {
  translation: string
  word: string
  sentence: string
  index: number
  activeIndex?: number
  i18n: MessageGetter
  original: string
  onClick: () => void
  onDoubleClick: () => void
  handleContextMenuChange: () => void
  handleContextMenuSpeak: () => void
  handleContextMenuDictionary: () => void
  speaking?: boolean
  ttsProvider: TTSProvider | null
  isDictionaryAvailable?: boolean
}

const SuggestionListItem2 = (props: SuggestionListItemProps) => {
  const { translation, index, activeIndex, original, word, sentence, ttsProvider, speaking } = props
  const isActive = index === activeIndex

  const dropdownReplaceText = original === word ?
    props.i18n('command_keep', [word]) :
    props.i18n('suggestion_change_hint', [original, word])

  const isOribiSpeakInstalled = ttsProvider === TTSProvider.ORIBI_SPEAK
  // const hotkeyCommand = index < 9 ? `alt+${index + 1}` : undefined // Alt ⌥

  const HeaderContent = () => {
    if ( !sentence ) return <span className='word'>{ word }</span>

    const html = sentence.replace(/<([^<>]+)>/g, `<span class="word">$1</span>`)
    
    return <span dangerouslySetInnerHTML={{
      __html: html
    }}></span>
  }
  
  return <List.Item
    title={ dropdownReplaceText }
    active={ isActive }
    onClick={ () => props.onClick() }
    key={ index }
    className='suggestion'
  >
    <List.Content>
      <List.Header
        style={{
          fontSize: '1.28571429em',
          lineHeight: '1.3'
        }}
      >
        <div className="suggestion-text" onDoubleClick={ props.onDoubleClick }><HeaderContent /></div>
      { ttsProvider !== null &&
        <div className="action">
          <Button
            basic={ !speaking }
            disabled={ speaking }
            color={ isOribiSpeakInstalled ? 'green' : 'grey' }
            style={{ padding: 4 }}
            icon='volume up'
            title={ props.i18n(isOribiSpeakInstalled ?
              'suggestion_listen_with_os' :
              'suggestion_listen')
            }
            onClick={ props.handleContextMenuSpeak }
          />
        </div>
      }
      </List.Header>
      { translation && 
      <List.Description style={{ marginTop: '.5em'}}>
        { translation }
      </List.Description> }
    </List.Content>
  </List.Item>
}

const SuggestionListItem = (props: SuggestionListItemProps) => {
  const { translation, index, activeIndex, original, word, sentence, ttsProvider, isDictionaryAvailable } = props
  const isActive = index === activeIndex
  const isOribiSpeakInstalled = ttsProvider === TTSProvider.ORIBI_SPEAK

  const dropdownReplaceText = original === word ?
    props.i18n('command_keep', [word]) :
    props.i18n('suggestion_change_hint', [original, word])

  // const hotkeyCommand = index < 9 ? `alt+${index + 1}` : undefined // Alt ⌥

  const HeaderContent = () => {
    if ( !sentence ) return <span className='word'>{ word }</span>

    const html = sentence.replace(/<([^<>]+)>/g, `<span class="word">$1</span>`)
    
    return <span dangerouslySetInnerHTML={{
      __html: html
    }}></span>
  }

  const actionGroupId = `action-group-${index}`
  
  const onDoubleClick = (event: MouseEvent) => {
    const target = event.target as Node | null
    const actionGroup = document.getElementById(actionGroupId)

    // Don't proceed if double click on action button
    if ( !!actionGroup && actionGroup.contains(target) ) {
      return
    }

    props.onDoubleClick()
  }

  return <List.Item
    active={ isActive }
    onClick={ props.onClick }
    onDoubleClick={ onDoubleClick }
    key={ index }
    className='suggestion'
  >
    <List.Content>
      <List.Header style={{
        fontSize: '1.28571429em',
        lineHeight: '1.3'
      }}>
        <HeaderContent />
      </List.Header>
      { translation && <List.Description style={{ marginTop: '.5em'}}>
        { translation }
      </List.Description> }
      { isActive && <>
      <Button.Group id={ actionGroupId } size='tiny' basic fluid style={{ marginTop: 8 }}>
        <Button
          style={{ padding: 4 }}
          title={ dropdownReplaceText }
          icon='check'
          onClick={ props.handleContextMenuChange }
        />
      { !!isDictionaryAvailable &&
        <Button
          style={{ padding: 4 }}
          icon='book'
          title={ props.i18n('suggestion_lookup', [word]) }
          onClick={ props.handleContextMenuDictionary }
        />
      }
      { ttsProvider !== null &&
        <Button
          style={{ padding: 4 }}
          icon='volume up'
          title={ props.i18n(isOribiSpeakInstalled ?
            'suggestion_listen_with_os' :
            'suggestion_listen')
          }
          onClick={ props.handleContextMenuSpeak }
        />
      }
      </Button.Group>
      </> }
    </List.Content>
  </List.Item>
}

interface Props {
  i18n: MessageGetter
  suggestions: SpellcheckSuggestion[]
  activeSuggestion?: SpellcheckSuggestion
  loading?: boolean
  visibleSuggestions: number
  original: string
  handleClick: (suggestion: SpellcheckSuggestion) => void
  handleDblClick: (suggestion: SpellcheckSuggestion) => void
  handleContextMenuChange: (suggestion: SpellcheckSuggestion) => void
  handleContextMenuSpeak: (suggestion: SpellcheckSuggestion) => void
  handleContextMenuDictionary: (word: string) => void
  app: OribiApp
  speakingSuggestion?: SpellcheckSuggestion
  displayEmptyDocMessage: boolean
  ttsProvider: TTSProvider | null
}

const Suggestions = (props: Props) => {
  const {
    suggestions,
    activeSuggestion,
    visibleSuggestions,
    original,
    i18n,
    app,
    displayEmptyDocMessage
  } = props
  const activeIndex = activeSuggestion ? activeSuggestion.index : undefined

  const isDictionaryAvailable = hasDictionary(app)

  return <Container>
  { displayEmptyDocMessage && 
    <EmptyDocMessage
      i18n={ props.i18n }
      app= { props.app }
    />
  }
    <List selection relaxed className='suggestions'>
    { suggestions.slice(0, visibleSuggestions).map((suggestion, index) => {
      const { translation, word, sentence } = suggestion
      return app === OribiApp.STAVA_REX ?
        <SuggestionListItem2
          key={ index }
          translation={ translation }
          word={ word }
          sentence={ sentence }
          activeIndex={ activeIndex }
          index={ index }
          original={ original }
          onClick={ () => props.handleClick(suggestion) } 
          onDoubleClick={ () => props.handleDblClick(suggestion) }
          handleContextMenuChange={ () => props.handleContextMenuChange(suggestion) }
          i18n={ i18n }
          ttsProvider={ props.ttsProvider }
          handleContextMenuSpeak={ () => props.handleContextMenuSpeak(suggestion) }
          handleContextMenuDictionary={ () => props.handleContextMenuDictionary(suggestion.word) }
          speaking={ props.speakingSuggestion === suggestion }
        /> :
        <SuggestionListItem
          key={ index }
          translation={ translation }
          word={ word }
          sentence={ sentence }
          activeIndex={ activeIndex }
          index={ index }
          original={ original }
          onClick={ () => props.handleClick(suggestion) } 
          onDoubleClick={ () => props.handleDblClick(suggestion) }
          handleContextMenuChange={ () => props.handleContextMenuChange(suggestion) }
          i18n={ i18n }
          ttsProvider={ props.ttsProvider }
          handleContextMenuSpeak={ () => props.handleContextMenuSpeak(suggestion) }
          handleContextMenuDictionary={ () => props.handleContextMenuDictionary(suggestion.word) }
          isDictionaryAvailable={ isDictionaryAvailable }
        />
    }) }
    </List>
  </Container>
}

export default Suggestions